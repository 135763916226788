import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import ContentLayout from 'UI/Components/ContentLayout';
import { onEnterKeydown } from 'Utils/keyboardEvents';
import styles from './style.module.css';

import { memo } from 'react';

export type ListItemProps = {
  className?: string;
  left?: React.ReactNode;
  leftVerticalAlign?: string;
  leftFixedWidthPixels?: number;
  main: React.ReactNode;
  mainHorizontalAlign?: string;
  mainTextWrap?: boolean;
  right?: React.ReactNode;
  rightFixedWidthPixels?: number;
  includebottomline?: boolean;
  bottom?: React.ReactNode;
  navItemTheme?: string;
  ariaLabel: string;
  noHorizontalPadding?: boolean;
  onClick?: React.MouseEventHandler;
};

const ListItem: FunctionComponent<ListItemProps> = ({
  className,
  left,
  leftVerticalAlign = 'center',
  leftFixedWidthPixels,
  main,
  mainHorizontalAlign = 'left',
  mainTextWrap = false,
  right,
  rightFixedWidthPixels,
  includebottomline = false,
  bottom,
  navItemTheme,
  ariaLabel,
  noHorizontalPadding,
  onClick,
}) => {
  return (
    <div
      className={cn(styles.listItem, {
        [styles.interactive]: onClick,
        [styles.noHorizontalPadding]: noHorizontalPadding,
      })}
      data-testid="list"
      color-theme={navItemTheme}
      role={onClick ? 'button' : ''}
      tabIndex={onClick ? 0 : undefined}
      aria-label={ariaLabel}
      onKeyDown={(e) => onEnterKeydown(e, onClick)}
    >
      <ContentLayout
        className={cn(className, {
          [styles.interactive]: onClick,
        })}
        left={left}
        leftVerticalAlign={leftVerticalAlign}
        leftFixedWidthPixels={leftFixedWidthPixels}
        main={main}
        mainHorizontalAlign={mainHorizontalAlign}
        mainTextWrap={mainTextWrap}
        right={right}
        rightFixedWidthPixels={rightFixedWidthPixels}
        includebottomline={includebottomline}
        bottom={bottom}
        onClick={onClick}
      />
    </div>
  );
};
export default memo(ListItem);
