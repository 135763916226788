import { MixPanelEvents } from './mixPanelEvents';
export type TrackingUser = {
  id?: string;
  emailAddress?: string;
  cloudEnv?: string;
};

export enum AvailableScreens {
  AppInsights = 'AppInsights',
  Home = 'Home',
  WlanViewAll = 'WlanViewAll',
  Network = 'Network',
  EmployeeZone = 'EmployeeZone',
  EmployeeLogin = 'EmployeeLogin',
  SecureZone = 'SecureZone',
  GuestZone = 'GuestZone',
  NotFound = 'NotFound',
  IntroPage = 'IntroPage',
  LoginPage = 'LoginPage',
  BellLoginPage = 'BellLoginPage',
  PartnerAuthenticationCallback = 'PartnerAuthenticationCallback',
  MagicLinkPage = 'MagicLinkPage',
  GlobalAuthPage = 'GlobalAuthPage',
  ResetPasswordPage = 'ResetPasswordPage',
  Settings = 'Settings',
  SettingsSecure = 'SettingsSecure',
  SettingsEmployee = 'SettingsEmployee',
  SettingsEmployeeLogin = 'SettingsEmployeeLogin',
  SettingsGuest = 'SettingsGuest',
  SettingsShield = 'SettingsShield',
  SettingsPods = 'SettingsPods',
  SettingsSupport = 'SettingsSupport',
  SettingsAccount = 'SettingsAccount',
  SettingsBusinessInfo = 'SettingsBusinessInfo',
  Shield = 'Shield',
  SsidConfiguration = 'SsidConfiguration',
}

export type AuthenticationType = 'Password' | 'Magic Link' | 'External IdP';

type GenericTrackEventParams = {
  eventName:
    | MixPanelEvents.LOGOUT
    | MixPanelEvents.GUEST_DOWNLOAD_CSV_MESSAGE
    | MixPanelEvents.GUEST_DOWNLOAD_CSV_SUCCESS
    | MixPanelEvents.GUEST_DOWNLOAD_TURN_ON_MESSAGE
    | MixPanelEvents.GUEST_DOWNLOAD_TURN_ON_SUCCESS
    | MixPanelEvents.GUEST_DOWNLOAD_NO_EMAILS_MESSAGE
    | MixPanelEvents.EMPLOYEE_LOGIN_SELFHEAL
    | MixPanelEvents.UNQUARANTINE_DEVICE;
  additionalContent?: Record<string, any>;
};

export type TrackEventParams =
  | GenericTrackEventParams
  | {
      eventName: MixPanelEvents.AUTHENTICATION_SUCCESS;
      additionalContent: {
        authenticationType: AuthenticationType;
      };
    }
  | {
      eventName: MixPanelEvents.SCREEN;
      additionalContent: {
        SCREEN: AvailableScreens;
      };
    }
  | {
      eventName: MixPanelEvents.EMPLOYEE_CREATE_SUCCESS;
      additionalContent: {
        nickname: string;
        imageId: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.GUEST_DOWNLOAD_TURN_ON_FAILURE
        | MixPanelEvents.GUEST_DOWNLOAD_CSV_FAILURE
        | MixPanelEvents.AUTHENTICATION_FAILURE;
      additionalContent: {
        errorMessage: string;
      };
    }
  | {
      eventName: MixPanelEvents.EMPLOYEE_LOGIN;
      additionalContent: {
        SCREEN: AvailableScreens.EmployeeLogin;
      };
    }
  | {
      eventName: MixPanelEvents.EMPLOYEE_LOGIN_ENABLEMENT;
      additionalContent: {
        ENABLE: boolean;
      };
    }
  | {
      eventName: MixPanelEvents.LOCATION_SUMMARY;
      additionalContent: {
        COUNT_DEVICES_SECURE: number;
        COUNT_DEVICES_EMPLOYEE: number;
        COUNT_DEVICES_GUEST: number;
        COUNT_MANUAL_EMPLOYEE: number;
        COUNT_WARDEN_EMPLOYEE: number;
      };
    }
  | {
      eventName: MixPanelEvents.EMPLOYEE_REMOVE_SUCCESS;
      additionalContent: {
        personId: string;
      };
    }
  | {
      eventName: MixPanelEvents.EMPLOYEE_UNBLOCK_SUCCESS;
      additionalContent: {
        idpUserId: string;
      };
    }
  | {
      eventName: MixPanelEvents.SSID_CONFIGURATION;
      additionalContent: {
        SCREEN: AvailableScreens.SsidConfiguration;
      };
    };
